.header-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.header-button-container h2 {
    margin: 0; /* Remove default margin from h2 */
}

.contributor-insights-table .button-container .button-primary,
.contributor-insights-table .button-container .button-save {
    margin-right: 10px;
}

.toggle-container {
    display: inline-flex;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.toggle-button {
    font-family: var(--text);
    background-color: var(--g200);
    border: none;
    color: var(--g500);
    padding: 8px 16px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 13px;
    margin: 0;
    cursor: pointer;
    transition: 0.3s;
    border-radius: 0;
}

.toggle-button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.toggle-button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.toggle-button:not(.active) {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

.toggle-button.active {
    background-color: var(--p500);
    color: white;
    box-shadow: none;
}

/*.toggle-button:hover {*/
/*    background-color: var(--p500);*/
/*    color: white;*/
/*}*/

input.target-name-input, input.group-name-input {
    width: 60%;
}

/* Target Survey */
.target-survey-container {
    padding: 0 5% 60px 5%;
}

.target-survey-container h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.likert-container {
    margin-bottom: 30px;
}

.likert-container p {
    font-weight: var(--semi-bold);
    font-size: 1.2rem;
    color: var(--primary);
}

.likert-scale {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    max-width: 600px;
    margin-top: 10px;
}

.likert-card {
    flex: 1;
    margin: 0 5px;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
}

.likert-card input[type="radio"] {
    display: none;
}

.likert-card:hover {
    background-color: var(--g150);
}

.likert-scale-label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid var(--g300);
    border-radius: 5px;
    transition: all 0.3s ease;
    height: 150px;
}

.target-survey-icons {
    font-size: 24px;
    color: var(--g500);
    margin-bottom: 10px;
}

.likert-card input[type="radio"]:checked + .likert-scale-label {
    background-color: var(--primary);
}

.likert-card input[type="radio"]:checked + .likert-scale-label,
.likert-card input[type="radio"]:checked + .likert-scale-label .target-survey-icons,
.likert-card input[type="radio"]:checked + .likert-scale-label .likert-text {
    color: var(--white);
}

.likert-text {
    font-size: 1em;
    text-align: center;
}

.likert-scale-label,
.likert-scale-label .material-icons,
.likert-scale-label .likert-text {
    transition: all 0.3s ease;
}

.survey-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    max-width: 600px;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background-color: var(--white);
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 20px;
}

.progress {
    height: 100%;
    background-color: var(--primary);
    border-radius: 5px;
    transition: width 0.3s ease;
}

.step-indicator {
    text-align: center;
    font-weight: bold;
    margin-bottom: 5px;
}

.survey-nav-button {
    padding: 12px 34px;
    font-size: 1.1rem;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-icon {
    width: 24px;
    height: 24px;
}

.survey-next-button {
    margin-left: auto;
}

.survey-nav-button .nav-icon:first-child {
    margin-right: 8px;
}

.survey-nav-button .nav-icon:last-child {
    margin-left: 8px;
}

/* Target Overlay */
.target-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.95);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6%;
    text-align: center;
    font-size: 15px;
    z-index: 1;
}

.target-overlay h3 {
    margin-bottom: 1em;
}

.target-overlay p {
    margin-bottom: 0.5em;
}

.contributor-type-chip {
    background: var(--p300);
    color: var(--white);
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 11px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    position: relative;
    font-weight: var(--normal);
    margin-left: 8px;
}

.contributor-type-benchmark {
    background: var(--g500);
}

.contributor-type-standard {
    background: var(--p500);
}