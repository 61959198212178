.tabs {
    display: flex;
    margin: 0;
    list-style: none;
    padding: 20px 1.5rem;
}

.tab-item {
    cursor: pointer;
    padding: 12px 30px;
    color: var(--g500);
    font-family: var(--text);
    font-size: 13px;
    font-weight: var(--bold);
    text-align: center;
    transition: color 0.3s, border-color 0.3s;
    border-bottom: 3px solid transparent; /* Initial transparent bottom border */
}

.tab-item:hover,
.tab-item.active {
    color: var(--p500);
    border-bottom: 3px solid var(--p500); /* Solid line under active/hovered tab */
}

.tab-content {
    padding: 20px;
    /*background-color: var(--g100);*/
}

.app-container-modified {
    background-color: var(--g200);
}

.app-container-modified .tab-content {
    background-color: var(--g200);
}

.app-container-modified .tab-item {
    background-color: var(--g300);
}

.app-container-modified .tab-item.active {
    background-color: var(--g200);
}

.app-container-modified table td,
.app-container-modified .filter-bar {
    background-color: var(--g100);
}

.more-button {
    display: none;
}

.dropdown-item {
    display: block;
    width: 100%;
}

.more-tabs-dropdown {
    display: none;
    position: absolute;
    right: 10px;
    background-color: var(--white);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    z-index: 10;
    min-width: 220px;
    padding: 10px;
}

.dropdown-container {
    position: relative;
    display: inline-block;
}

.dropdown-menu {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 1000;
    background-color: var(--white);
    border: 1px solid var(--g100);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    margin-top: 4px;
  }

.more-tabs-dropdown-item {
    padding: 10px;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: center;
}

.more-tabs-dropdown-item:hover {
    background-color: var(--g100);
}

@media (max-width: 768px) {
    .tab-item {
        font-size: 12px;
    }

    .more-button {
        display: inline-flex;
    }

    .more-tabs-dropdown {
        display: block;
    }

    .tabs {
        padding: 0;
        padding-inline: 8px;
    }

    .tab-item {
        padding: 10px 16px;
    }
}

@media (max-width: 1024px) {
    .tab-item {
        font-size: 12px;
    }
}
