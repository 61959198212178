/* App.css */
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@import url('./styles/colors.css');
@import url('./styles/fonts.css');

@import url('./styles/global.css');
@import url('./styles/buttons.css');
@import url('./styles/forms.css');
@import url('./styles/layout.css');
@import url('./styles/header.css');
@import url('./styles/permissions.css');
@import url('./styles/sidebar.css');
@import url('./styles/searchbar.css');
@import url('./styles/tables.css');
@import url('./styles/charts.css');
@import url('./styles/dashboard.css');
@import url('./styles/shareModal.css');
@import url('./styles/modal.css');
@import url('./styles/archetype.css');
@import url('./styles/profile.css');
@import url('./styles/groups.css');
@import url('./styles/tabs.css');
@import url('./styles/assessments.css');
@import url('./styles/interviewGuide.css');
@import url('./styles/targets.css');
@import url('./styles/filter-bar.css');
@import url('./styles/dropdown-button.css');
@import url('./styles/entity.css');
@import url('./styles/statistics-boxes.css');
@import url('./styles/settings.css');
@import url('./styles/toast.css');
@import url('./styles/premiumreport.css');
@import url('./styles/AriaChat.css');
@import url('./styles/pricing-table.css');
@import url('./styles/dark-mode.css');
@import url('./styles/responsive.css');

@import url('./styles/print.css');

/* Loading */
.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50vh;
}

.loading-icon {
    animation: spin 2s linear infinite;
    height: 80px;
    width: 80px;
}

.loading-text {
    margin-top: 20px;
    font-size: 1.2rem;
    color: var(--g700);
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 1024px) {
    .sidebar-navigation li a {
        font-size: 12px;
    }

    .sidebar-navigation li svg {
        width: 26px;
        height: 26px;
    }
}

li.mobile-logout {
    display: none;
}

@media screen and (max-width: 768px) {
    li.mobile-logout {
        display: flex;
    }

    .mobile-assumed-entity {
        margin-left: 26px;
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {

    .sidebar,
    .header-profile-container {
        display: none;
    }
}

.detailed-settings-page-title {
    background-color: var(--p300);
    color: var(--white);
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    /* Adjust this as needed for spacing between items */
    gap: 6px;
}

.page-bottom-spacing {
    padding-bottom: 50px;
}

table tbody tr.hovered-row>td {
    background-color: var(--g150);
}

/* Thumbs Up/Down Icons */
.thumbs-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    cursor: pointer;
}

.thumbs-icon path {
    fill: var(--g200);
}

.thumbs-icon.active path {
    fill: var(--green);
}

.thumbs-icon.thumbs-down.active path {
    fill: var(--red);
}

/* SVG Attribute Charts */
.svg-container {
    display: flex;
    /* Use Flexbox */
    align-items: center;
    /* Center items vertically */
    justify-content: space-between;
    /* Space between the attribute name and the SVG */
    width: 100%;
    position: relative;
    background-color: var(--white);
    /* padding-right: 7px; */
}

.svg-container-top {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.svg-container-bottom {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.attribute-name {
    flex: 0 0 auto;
    width: 150px;
    max-width: 150px;
    font-size: 14px;
    font-weight: var(--semi-bold);
    color: var(--p500);
    margin-right: 0;
    text-align: center;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px;
    cursor: pointer;
}

.svg-container-hover .attribute-name {
    font-weight: var(--bold);
}

.svg-container-hover {
    background-color: var(--g150);
}

.tooltip-text {
    font-size: 20px;
    font-weight: var(--semi-bold);
    fill: var(--white);
}

@media screen and (max-width: 768px) {
    .attribute-name {
        width: 100px;
        max-width: 100px;
        font-size: 12px;
        font-weight: var(--normal);
    }
}

.label-container {
    background-color: transparent;
    max-height: 30px;
}

.bottom-label-container {
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 20px;
}

.scale-label-text {
    fill: var(--p500);
    font-size: 16px;
    /* Default font size */
}

/* SVG Chart Filter/Sort Drop-Downs */
/* Label styling */
.visualization-area label {
    display: block;
    font-family: var(--text);
    color: var(--g500);
    margin-bottom: 8px;
    /* Adjust as needed */
    font-size: 14px;
    /* Adjust as needed */
}

/* Visualization Drop-Down Styling */
.visualization-area select {
    -webkit-appearance: none;
    /* Disable default styling in WebKit browsers like Safari */
    appearance: none;
    font-family: var(--text);
    color: var(--p500);
    padding: 12px 8px;
    border: 1px solid var(--g500);
    /* Default border color */
    border-radius: 7px;
    background-color: var(--white);
    cursor: pointer;
    outline: none;
    /* Removes the default focus outline */
}

.visualization-area select:focus {
    border-color: var(--p500);
    /* Changes border color on focus */
}

.visualization-area option {
    background-color: var(--white);
    /* White background */
    color: var(--p500);
    /* Text color */
    font-family: var(--text);
    /* Attempts to set font, but might not work in all browsers */
}

/* Flex container for dropdowns */
.visualization-area .dropdowns-container {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    /* Adjust the space between dropdowns */
    margin-bottom: 20px;
    /* Space between dropdowns and the content below */
}

/* Responsive adjustment */
@media (max-width: 768px) {
    .label-container {
        display: none;
    }
}

.heading-top-margin {
    margin-top: 20px;
}

.forgetLink {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.checkBoxWrapper {
    align-items: unset !important;
}

.ml-0 {
    margin-left: 0px !important;
    gap: 8px;
    margin-bottom: 5px;
}

.adjective-definition.showDefination {
    opacity: 1;
}

.loginFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 20px;
}

 .signInMSBtn {
    font-family: "Noto Sans", arial, sans-serif;
    background-color: white;
    border: 1px solid #dadce0;
    font-size: 14px;
    height: 40px;
    padding: 0 12px;
    border-radius: 4px;
    color: #3c4043;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
 }

 .signInMSBtn svg {
    width: 25px;
    height: 25px;
 }
 
 .user-accessibility-section {
    margin-top: 2rem;
    padding: 0.7rem 0 0.3rem 0;
}

.help-text {
    margin-top: 0.5rem;
    line-height: 1.4;
    max-width: 800px;
}

/* User Accessibility and Assessment Taker Notification Styles */
.user-accessibility-section,
.assessment-taker-notification {
    margin-top: 2rem;
    padding: 0.7rem 0 0.3rem 0;
}

.notification-row {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 1rem;
}

.notification-input {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.notification-input input[type="checkbox"] {
    margin: 0;
    cursor: pointer;
    width: 16px;
    height: 16px;
}

.notification-select {
    margin-left: auto;
}

.help-text {
    font-size: 15px;
    margin-top: 0.5rem;
    line-height: 1.4;
    max-width: 800px;
}