.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
    pointer-events: auto;
}

.modal-content {
    position: relative;
    margin: 6% auto;
    padding: 30px;
    border-radius: 7px;
    background-color: var(--white);
    color: var(--black);
    width: 85%;
    max-width: 1200px;
    overflow-y: auto;
    max-height: 90vh;
    pointer-events: auto;
}
/* 
.modal-close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 48px;
    height: 48px;
    background-color: var(--p500);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modal-close-icon svg {
    width: 24px;
    height: 24px;
    fill: var(--white);
} */

h2.modal-title {
    font-size: 2rem;
    text-align: center;
}

.modal-subtitle {
    text-align: center;
    font-size: 1.2rem;
    font-weight: var(--bold);
}

.modal-description-title {
    font-size: 1.6rem;
    color: var(--p500);
    margin-top: 12px;
}

.modal-score-information {
    text-align: center;
}

.modal-description {
    padding: 0 50px 20px;
    font-size: 1.1rem;
}

.modal-insights {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
    font-size: 15px;
}

.modal-insights-two-column {
    width: 48%;
    margin-right: 2%;
}

.modal-insights-three-column {
    width: 31%;
    margin-right: 1%;
}

.modal-title, h4 {
    color: var(--p700);
}

h4.superpower, h4.challenge-area, h4.score-analysis {
    margin: 8px 0;
}

.modal-insights-two-column p, .modal-insights-three-column p {
    margin-top: 0;
}

.speedometer-icon, .speedometer-icon-extreme {
    width: 80px;
    height: auto;
    margin: auto;
    color: var(--p500);
}

.speedometer-icon-extreme {
    color: var(--o500);
}

.modal-score-low {
    opacity: 0.6;
}

.email-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1050;
}

.email-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.email-modal-content input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.email-modal-content button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

/* New Contributor & New User Modal */
.selected-contributors, .selected-users {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 10px;
}

.contributor-tag, .user-tag {
    background-color: var(--p500);
    color: var(--white);
    padding: 2px 5px;
    border-radius: 3px;
    display: flex;
    align-items: center;
}

.remove-contributor, .remove-user {
    margin-left: 5px;
    cursor: pointer;
}

.contributor-input-container, .user-input-container {
    position: relative;
}

.contributor-email-input, .user-email-input, .user-search-input {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--p500);
    border-radius: 4px;
    font-size: 14px;
    font-family: var(--headings);
    margin-bottom: 16px;
}

.suggestions-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: var(--white);
    border: 1px solid var(--p500);
    border-radius: 4px;
    z-index: 1000;
}

.suggestion-item {
    padding: 8px;
    cursor: pointer;
}

.suggestion-item:hover {
    background-color: var(--p500);
    color: var(--white);
}

.modalinvalid-feedback {
    color: var(--error);
    font-size: 14px;
    margin-top: -12px;
    margin-bottom: 16px;
}

/* WorkStylesChartModal */
.workstyles-chart-modal .modal-content {
    width: 60%;
    max-width: 1400px;
    padding: 20px;
}

.workstyles-chart-modal .workstyles-chart-container,
.workstyles-chart-modal .resonance-chart-container {
    max-width: 100%;
}

.modal-close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
    background-color: var(--p500);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.modal-close-icon svg {
    width: 20px;
    height: 20px;
    fill: var(--white);
}

.modal-subtitle {
    text-align: center;
    font-size: 1.2rem;
    font-weight: var(--bold);
}

@media screen and (max-width: 1024px) {
    .workstyles-chart-modal .modal-content {
        width: 80%;
    }
}

@media screen and (max-width: 768px) {
    .modal-content {
        width: 95%;
        padding: 20px 13px;
    }

    .workstyles-chart-modal .modal-title {
        font-size: 1.4rem;
        padding-bottom: 10px;
    }

    .workstyles-chart-modal .workstyles-chart-heading {
        font-size: 1.2rem;
    }

    .modal-description {
        padding: 0 0 20px;
        font-size: 1rem;
    }

    .modal-insights-two-column {
        width: 100%;
    }

    .modal-insights-three-column {
        width: 100%;
    }

    .modal-score-low {
        display: none;
    }

    .workstyles-chart-modal .modal-content {
        width: 95%;
        padding: 25px;
    }
}
