/* header.css */
.app-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 500;
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Right-align everything */
    flex-wrap: nowrap;
    padding: 0.5rem 1rem;
    color: white;
    min-height: 54px;
    transition: transform 0.3s ease-in-out;
    pointer-events: none; /* Make the header background non-interactive by default */
}

/* Make specific interactive elements clickable */
.app-header .search-bar-container,
.app-header .header-profile-container,
.app-header .mobile-logo {
    pointer-events: auto;
}

.app-header.hidden {
    transform: translateY(-100%);
}

.app-header.visible {
    transform: translateY(0);
}

.mobile-logo {
    display: none;
}

.header-profile-container {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 1rem;
}

.header-profile-avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.caret-icon {
    margin-left: 8px;
    width: 12px;
    height: 12px;
    color: var(--p500);
}

.header-profile-dropdown {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    color: black;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 10;
    list-style: none;
    min-width: 145px;
}

.header-profile-dropdown li {
    padding: 10px 20px;
    margin: 0;
}

.header-profile-dropdown li a {
    display: block;
    color: var(--p500);
    text-decoration: none;
    font-size: 15px;
    font-weight: var(--normal);
}

.header-profile-dropdown li:hover {
    background-color: var(--g100);
}

.search-bar-container {
    display: flex;
    align-items: center;
}

.search-bar-icon {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    color: var(--p300);
}

.search-bar-line {
    position: absolute;
    right: 40px;
    top: 50%;
    transform: translateY(-50%);
    height: 20px;
    width: 1px;
    background-color: var(--p300);
}

.magnifying-glass-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: var(--white);
    opacity: 0.6;
    width: 28px;
    height: 28px;
}

.magnifying-glass-icon.active {
    opacity: 1;
}

.magnifying-glass-icon svg {
    width: 100%;
    height: 100%;
}

.mobile-nav-icon {
    width: 28px;
    height: 28px;
}

.hamburger-icon {
    font-size: 26px;
}

.mobile-navigation {
    position: absolute;
    top: 100%; /* Adjust based on your header's height */
    left: 0;
    width: 100%;
    background-color: var(--p500); /* Match your theme */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional */
    z-index: 100; /* Ensure it's above other content */
    display: none; /* Initially hidden */
}

/* Dark Mode Dropdown */
.header-profile-dropdown .dark-mode-dropdown {
    position: relative;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--p500);
    font-weight: var(--normal);
}

.header-profile-dropdown .dark-mode-dropdown:hover {
    background-color: var(--g100);
}

.header-profile-dropdown .dark-mode-dropdown .theme-caret {
    margin-right: 2px;
    margin-left: -14px;
    transition: transform 0.3s ease;
}

.header-profile-dropdown .dark-mode-dropdown.active .theme-caret {
    transform: rotate(-90deg);
}

.header-profile-dropdown .dark-mode-dropdown .theme-options {
    position: absolute;
    left: -120px;
    top: 0;
    background-color: var(--white);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 11;
    width: 120px;
    font-family: var(--text);
    font-weight: var(--normal);
}

.header-profile-dropdown .dark-mode-dropdown .theme-options button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    border: none;
    background: none;
    cursor: pointer;
    text-align: left;
    font-family: 'Inter', sans-serif;
    color: var(--p500);
}

.header-profile-dropdown .dark-mode-dropdown .theme-options button:hover {
    background-color: var(--g100);
}

.header-profile-dropdown .dark-mode-dropdown .theme-options button.active {
    background-color: var(--p100);
    color: var(--p500);
}

.header-profile-dropdown .dark-mode-dropdown .theme-options button svg {
    margin-right: 8px;
}

.mobile-assumed-entity .button-primary {
    background-color: var(--p300);
    font-size: 0.7rem;
    border-radius: 4px;
}

/* Responsive adjustments for header */
@media (max-width: 768px) {
    .mobile-logo {
        display: block;
        height: 48px;
        padding: 4px;
    }

    .app-header {
        justify-content: center;
        background-color: var(--p500);
        box-shadow: var(--shadow-large);
    }

    .logo {
        cursor: pointer;
        height: 38px;
    }

    .header-profile-container {
        display: none;
    }

    .search-bar-container {
        padding: 1rem;
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;
        z-index: 1000;
    }

    .search-bar-container {
        display: block;
    }

    .mobile-bottom-icons {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: var(--p500);
        padding: 0.6rem 0;
        z-index: 1000;
    }

    .mobile-bottom-icons a,
    .mobile-bottom-icons button {
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--white);
        text-decoration: none;
        font-size: 18px;
        width: 30px;
        transition: color 0.3s ease, opacity 0.3s ease;
        opacity: 0.6;
    }

    .mobile-bottom-icons a.active,
    .mobile-bottom-icons button.active {
        opacity: 1;
    }

    .mobile-bottom-icons a.inactive,
    .mobile-bottom-icons button.inactive {
        opacity: 0.6;
    }

    .mobile-bottom-icons .hamburger-menu {
        border: none;
        background: none;
    }

    .mobile-navigation {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 100vh;
        background-color: var(--p500);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 100;
        display: none;
    }
}

.sidebar-navigation .theme-menu {
    position: relative;
}

.sidebar-navigation .theme-menu .theme-submenu {
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: var(--g100);
}

.sidebar-navigation .theme-menu .theme-submenu li {
    padding: 0;
}

.sidebar-navigation .theme-menu .theme-submenu button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    border: none;
    background: none;
    cursor: pointer;
    text-align: left;
    font-family: 'Inter', sans-serif;
    color: var(--p500);
}

.sidebar-navigation .theme-menu .theme-submenu button:hover {
    background-color: var(--g200);
}

.sidebar-navigation .theme-menu .theme-submenu button svg {
    margin-right: 10px;
}

.search-bar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
}

.search-bar-overlay.visible {
    opacity: 1;
    pointer-events: auto;
}

.search-bar-overlay .search-bar-container {
    background-color: transparent;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
}

.search-bar-overlay.visible .search-bar-container {
    transform: translateX(0);
}
