.filter-bar {
    display: flex;
    align-items: center;
    padding: 5px 1.5rem;
    position: relative;
    margin-bottom: -20px;
    justify-content: space-between;
}

.filter-bar-left {
    display: flex;
    align-items: center;
}

.filter-bar-right {
    display: flex;
    align-items: center;
}

.filter-button {
    background-color: var(--g100);
    color: var(--primary);
    border: none;
    border-radius: 20px;
    padding: 6px 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 0;
    margin-right: 5px;
}

.filter-button svg {
    margin-right: 6px;
}

.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    color: var(--primary);
}

.spin {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.icon-button:hover {
    opacity: 0.7;
}

.active-filters {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
}

.filter-chip {
    background-color: var(--g150);
    border-radius: 16px;
    padding: 5px 10px;
    margin: 2px;
    display: flex;
    align-items: center;
    font-size: 13px;
}

.filter-chip svg {
    margin-left: 3px;
    cursor: pointer;
    height: 12px;
}

.clear-all {
    background: none;
    border: none;
    color: var(--primary);
    cursor: pointer;
    margin-left: 10px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.filter-close-button svg {
    margin-right: 6px;
    fill: var(--white)
}

.filter-count {
    background-color: var(--primary);
    color: var(--white);
    border-radius: 50%;
    width: 18px;
    height: 18px;
    font-size: 11px;
    margin-left: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    min-width: 18px;
}

.close-button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--g400);
}

.filter-modal {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-height: 90%;
    overflow-y: auto;
    width: 80%;
    max-width: 600px;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    position: sticky;
    top: 0;
    background-color: var(--p100);
    border-radius: 10px;
    z-index: 1;
    padding: 10px;
    border-bottom: 1px solid var(--g200);
}

.modal-header h2 {
    margin-bottom: 0;
}

.filter-section {
    margin-bottom: 20px;
}

.filter-section h3 {
    margin-bottom: 10px;
}

.search-input {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    border-radius: 4px;
    padding: 5px;
}

.search-input input {
    border: none;
    outline: none;
    flex-grow: 1;
    margin-left: 5px;
}

.filter-checkbox-list {
    max-height: 160px;
    overflow-y: auto;
    border-radius: 4px;
    padding: 5px;
}

.filter-entities-list {
    max-height: 160px;
    border-radius: 4px;
    border: 1px solid var(--g200);
    overflow-y: auto;
    padding: 5px;
}

.filter-checkbox-label {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.filter-checkbox-label input[type="checkbox"] {
    margin-right: 8px;
}

.tab-content .filter-bar {
    margin-bottom: 0;
    padding: 0;
}

.search-input,
.filter-checkbox-list {
    border: 1px solid var(--g200);
}

/* Date Picker */
.quick-date-button {
    background: none;
    border: none;
    color: var(--p500);
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    font: inherit;
}

.quick-date-button:hover,
.quick-date-button:focus {
    text-decoration: none;
    color: var(--p700);
}

.date-picker .react-datepicker {
    font-family: var(--text);
}

.quick-date-options {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.quick-date-link-container {
    margin-bottom: 5px;
}

.react-datepicker {
    border: none !important;
    box-shadow: var(--shadow-input) !important;
}

.react-datepicker__header {
    background-color: var(--g100) !important;
    border-bottom: 1px solid var(--g200) !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    width: 2rem !important;
    line-height: 2rem !important;
}

.react-datepicker__day--in-range:not(.react-datepicker__day--selected) {
    background-color: var(--p100) !important;
    color: var(--p500) !important;
    border-radius: 0 !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--selected) {
    background-color: var(--p100) !important;
    color: var(--p500) !important;
}

.react-datepicker__day--selecting-range-end:not(.react-datepicker__day--selected) {
    background-color: var(--p500) !important;
    color: var(--white) !important;
}

.react-datepicker__day--range-start,
.react-datepicker__day--range-end {
    background-color: var(--p500) !important;
    color: var(--white) !important;
    border-radius: 0 !important;
}

/* Make sure the end date is highlighted correctly */
.react-datepicker__day--range-end.react-datepicker__day--selected,
.react-datepicker__day--range-end.react-datepicker__day--in-range,
.react-datepicker__day--selecting-range-end,
.react-datepicker__day--range-end,
.react-datepicker__day--in-selecting-range {
    background-color: var(--p500) !important;
    color: var(--white) !important;
    border-radius: 0 !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: var(--p500) !important;
    color: var(--white) !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: var(--g700) !important;
}

.react-datepicker__day {
    margin: 0 !important;
}

@media (max-width: 768px) {
    .filter-bar {
        padding: 5px 0;
    }

    .filter-modal {
        padding: 20px;
        width: 92%;
    }
}