.button {
    cursor: pointer;
    padding: 9px 18px 9px 14px;
    /*margin-left: auto;*/
    border: 0;
    border-radius: 17px;
    color: var(--white);
    font-family: var(--text);
    font-weight: var(--semi-bold);
    font-size: 0.8rem;
    /*text-transform: uppercase;*/
    white-space: nowrap;
    box-shadow: var(--shadow-input);
    display: inline-flex; /* Use inline-flex to keep the element inline but allow flex properties */
    align-items: center; /* Center items vertically */
}

.button-icon {
    height: 1em;
    width: 1em;
    vertical-align: middle;
    margin-right: 2px;
    fill: currentColor;
}

.button-sm {
    padding: 4px 8px;
}

/* Actions Button */
.actions-icon {
    vertical-align: middle;
    cursor: pointer;
}

.actions-dropdown-trigger {
    text-align: right;
}

.actions-dropdown {
    position: absolute;
    background-color: var(--white);
    border: 1px solid var(--g200);
    border-radius: 4px;
    padding: 5px 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-width: 150px;
}

.actions-dropdown .action {
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px 12px;
    transition: background-color 0.2s;
    white-space: nowrap;
    color: var(--g700);
    font-family: var(--text);
}

.actions-dropdown .action:hover {
    background-color: var(--p500);
    color: var(--white);
}

/* Archive Button */
.archive-icon, .reset-pw-icon, .remove-icon, .add-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    color: var(--p300);
    transition: fill 0.3s;
    vertical-align: middle;
}

.archive-icon:hover, .remove-icon:hover  {
    color: var(--red);
}

.add-icon:hover  {
    color: var(--green);
}

.add-icon.success {
    color: var(--green);
    cursor: default;
}

.archive-dropdown {
    position: fixed;
    right: 0;
    transform: translateX(-10%);
    background-color: var(--white);
    border: 1px solid var(--g200);
    border-radius: 4px;
    padding: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    display: flex;
    align-items: center;
}

.archive-dropdown p {
    margin: 0 10px 0 0;
}

.archive-dropdown .button {
    margin-left: 10px;
}

/* .back-button {
    margin-right: 8px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    line-height: 0;
}

.back-button svg {
    width: 24px;
    height: 24px;
    color: var(--p500);
    vertical-align: middle;
} */

.detailed-settings-page-title .back-button svg {
    color: var(--white);
}

.button-primary {
    background-color: var(--p500);
}

.button-danger {
    background-color: var(--red);
}

.button-cancel, .copy-link-button, .share-model-button, .button-grey {
    background-color: var(--g100);
    color: var(--p500);
}

.button-save {
    background-color: var(--p300);
}

button:disabled {
    background-color: var(--g300);
    color: var(--g200);
    cursor: not-allowed;
}

.copy-icon, .share-icon, .view-icon, .new-icon {
    width: 14px;
    height: 14px;
    margin-right: 6px;
    vertical-align: middle;
}

.icon-white {
    fill: var(--white);
}

.icon-primary {
    fill: var(--primary);
}

.icon-grey {
    fill: var(--grey);
}

.copied-message {
    margin-left: 10px;
    color: var(--g500);
    font-size: 14px;
}

.back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    flex-shrink: 0;
    margin-left: -5px;
}

.back-button svg {
    width: 24px;
    height: 24px;
    color: var(--p500);
}

/* Alerts */
.success-message {
    color: var(--p500);
    font-size: 0.9rem;
    margin-bottom: 0;
    transition: opacity 0.5s ease-in-out;
}

.success-message.show {
    opacity: 1;
}

.group-buttons {
    display: flex;
    gap: 10px;
}

@media (max-width: 575px){
    .text-hidden-on-mobile{
        padding: 10px;
    }
    .text-hidden-on-mobile span{
        display: none;
    }
    .text-hidden-on-mobile svg{
        margin-right: 0px;
    }
}