.share-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.share-modal-content {
  background-color: var(--white);
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow-y: visible;
}

.custom-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.lodaing-padding {
  padding: 8px 12px;
}

.custom-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #cecece;
  border-radius: 10px;
}

.custom-scroll.custom-scroll::-webkit-scrollbar-thumb {
  background: #815dd3;
  border-radius: 10px;
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: gray;
}

.share-modal-dropdown::-webkit-scrollbar {
  display: none;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
}

h2 {
  margin: 0 0 20px;
  font-size: 1.5rem;
  color: var(--p500);
}

.share-modal-input-container {
  position: relative;
}

.share-input {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  background-color: var(--g100);
  border: 1px solid var(--g200);
  border-radius: 4px;
  outline: none;
  transition: border 0.3s;
}

.share-input:focus {
  border: 1px solid var(--p700);
}

.share-modal-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  border-radius: 4px;
  z-index: 1000;
  padding: 8px 0;
  list-style: none;
  margin: 0;
  width: 100%;
}

.share-modal-dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
  white-space: nowrap;
}

.share-modal-dropdown-item:hover {
  background-color: #f0f0f0;
}

.share-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 16px;
  padding-top: 12px;
  border-top: 1px solid #ddd;
}

.cancel-button,
.share-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  background-color: #f0f0f0;
  color: #333;
}

.cancel-button:hover {
  background-color: #e0e0e0;
}

.share-button {
  background-color: var(--p700);
  color: var(--white);
}

.share-button:hover:not(:disabled) {
  background-color: var(--p700);
}

.input-container {
  position: relative;
}

.error {
  color: var(--red);
  text-align: end;
}

.share-action {
  margin: 0px;
  text-align: start;
  display: flex;
  gap: 6px;
  align-items: center;
}

.shared-users-heading {
    font-size: 16px;
    color: var(--g700);
    margin: 24px 0 12px 0;
    font-weight: 500;
}

.shared-users-container {
    background: var(--g100);
    border: 1px solid var(--g200);
    border-radius: 4px;
    padding: 16px;
}

.shared-users-container .shared-users-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.shared-user-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid var(--g200);
}

.shared-user-row:last-child {
    border-bottom: none;
}

.shared-user-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.shared-user-name {
    font-size: 15px;
    color: var(--black);
    font-weight: var(--normal);
}

.shared-user-email {
    font-size: 13px;
    color: var(--g700);
}

.remove-user-button {
    background: none;
    border: none;
    color: var(--g500);
    font-size: 18px;
    cursor: pointer;
    padding: 4px 8px;
    transition: color 0.2s;
}

.remove-user-button:hover {
    color: var(--red);
}