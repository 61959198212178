/* Container */
.premium-report {
    padding-left: 80px;
}

/* Navigation */
.report-nav {
    position: fixed;
    left: calc(80px + 1rem); /* Account for container padding */
    top: 380px;
    transform: translateY(-50%);
    width: 260px;
    max-height: 80vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.report-nav ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.report-nav li {
    padding: 0.75rem 1rem;
    cursor: pointer;
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2s ease;
}

.report-nav li:hover {
    background-color: var(--p100);
}

.report-nav li.active {
    background-color: var(--p500);
    color: white;
}

/* Scrollbar styling */
.report-nav::-webkit-scrollbar {
    width: 6px;
}

.report-nav::-webkit-scrollbar-track {
    background: var(--p100);
}

.report-nav::-webkit-scrollbar-thumb {
    background: var(--p300);
    border-radius: 3px;
}

.report-nav::-webkit-scrollbar-thumb:hover {
    background: var(--p500);
}

/* Content */
.report-content {
    margin-left: 220px; /* nav width + some space */
    max-width: 800px;
    padding: 1.5rem;
}

/* Chart Styling */
.aptive-content .workstyles-svg, 
.aptive-content .resonance-chart-container {
    filter: none !important;
}

.aptive-content .workstyles-chart-heading {
    font-size: 1.25rem !important;
    color: var(--p500) !important;
}

/* Existing aptive-content styles */
.aptive-content {
    font-weight: 300;
    line-height: 1.4;
}

.aptive-content h1, 
.aptive-content h3 {
    color: var(--p500);
}

/* Ensure smooth scrolling */
html {
    scroll-behavior: smooth;
}

/* Add padding for scroll targets */
.aptive-content h1 {
    padding-top: 2rem;
    scroll-margin-top: 2rem;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .premium-report {
        padding-left: 0;
    }

    .report-nav {
        display: none; /* Hide navigation on mobile */
    }

    .report-content {
        margin-left: 0;
        padding: 1rem 0;
        width: 100%;
    }

    /* Adjust text sizes for mobile */
    .aptive-content {
        font-size: 0.9rem;
    }

    .aptive-content h1 {
        font-size: 1.5rem;
    }

    .aptive-content h2 {
        font-size: 1.3rem;
    }

    .aptive-content h3 {
        font-size: 1.1rem;
    }

    .aptive-content h4 {
        font-size: 1rem;
    }

    /* Ensure lists and other content fit */
    .aptive-content ul,
    .aptive-content ol {
        padding-left: 1.5rem;
    }
}

/* Print styles */
@media print {
    /* Hide navigation and reset layout */
    .report-nav {
        display: none;
    }

    .premium-report {
        padding: 0;
    }

    .report-content {
        margin: 0;
        max-width: none;
        padding: 0;
    }

    /* Heading styles */
    .aptive-content h1 {
        border: none !important;
        break-before: page;
        color: var(--p500) !important;
    }

    /* First h1 shouldn't force a page break */
    .aptive-content > div:first-child h1:first-child {
        break-before: avoid;
        margin-top: -26px !important;
    }

    /* Ensure heading colors print correctly */
    .aptive-content h1, h3 {
        color: var(--p500) !important;
    }

    .aptive-content h2, h4 {
        color: var(--black) !important;
    }

    /* Hide scrollbars */
    ::-webkit-scrollbar {
        display: none;
    }

    /* Chart handling */
    .report-charts {
        page-break-inside: avoid;
        margin: 2rem 0;
    }

    /* Additional print optimizations */
    * {
        background: transparent !important;
        box-shadow: none !important;
    }

    /* Ensure lists stay with their headings */
    .aptive-content ul,
    .aptive-content ol {
        break-before: avoid;
    }

    .aptive-content .workstyles-chart-container, 
    .aptive-content .resonance-chart-container {
        max-width: 60% !important;
    }

    /* Ensure images print properly */
    img {
        max-width: 100% !important;
        page-break-inside: avoid;
    }
}