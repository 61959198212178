  .assessment-details-header-container {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .date-email-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .adjective-selection-grid h3 {
    margin-bottom: 10px;
  }

  .adjective-selection-grid ul {
    list-style-type: none;
    padding: 0;
  }

  .adjective-selection-grid li {
    margin-bottom: 5px;
  }

  .assessment-row:first-child td {
    padding-top: 10px;
  }

  /* Container for dropdown */
  .dropdown-container {
    position: relative;
    width: 200px;
    border-radius: 7px;
    border: 1px solid var(--p500);
    color: var(--p500);
    font-family: var(--headings);
    font-size: 14px;
    background-color: var(--white);
  }

  .dropdown-header {
    padding: 10px;
    cursor: pointer;
  }

  .dropdown-list {
    max-height: 300px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 10;
  }

  .dropdown-item {
    padding: 8px;
    cursor: pointer;
  }

  .dropdown-item:hover {
    background-color: #f5f5f5;
  }

  .infinite-loading {
    text-align: center;
    padding: 10px;
  }


  th.iscp-column {
    text-align: center;
  }

  .table-profile,
  .table-archetype {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  .archetype-icon {
    color: var(--p300);
  }

  .archetype-icon-container {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 0.9rem;
  }

  .archetype-icon-container svg {
    height: 32px;
    width: 32px;
  }

  svg.archetype-header-icon path {
    fill: var(--p300);
  }

  .floating-navigation {
    position: fixed;
    bottom: 20px;
    right: 80px;
    display: flex;
    gap: 10px;
    z-index: 99;
    pointer-events: auto;
  }

  .nav-button-container {
    position: relative;
  }

  .nav-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: var(--p500);
    color: white;
    border-radius: 50%;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }

  .nav-button:hover {
    background-color: var(--p700);
  }

  /* Traits Spectrum */
  .traits-tab {
    padding: 1rem;
    background: white;
    border-radius: 0.5rem;
    filter: drop-shadow(var(--filter-large));
    max-width: 800px;
  }

  .traits-tab-summary {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    max-width: 80%;
  }

  .attribute-spectrum {
    width: 100%;
    padding: 0 20px;
    position: relative;
  }

  .spectrum-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
  }

  .spectrum-line {
    position: relative;
    height: 60px;
    display: flex;
    align-items: center;
    flex: 1;
  }

  .line-background {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: var(--g200);
  }

  .tick {
    position: absolute;
    width: 1px;
    background-color: var(--g300);
    transform: translateX(-50%);
  }

  .tick-small {
    height: 8px;
    top: -4px;
  }

  .tick-large {
    height: 16px;
    top: -8px;
  }

  .labels-container {
    display: none;
  }

  .label {
    position: relative;
    z-index: 1;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: var(--g700);
    width: 170px;
    flex-shrink: 0;
  }

  .left-label {
    text-align: right;
    padding-right: 20px;
  }

  .right-label {
    text-align: left;
    padding-left: 20px;
  }

  .marker {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 300;
    transition: left 0.3s ease;
    z-index: 3;
    cursor: pointer;
  }

  .circle-label {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    color: var(--white);
    font-size: 14px;
    font-weight: var(--semi-bold);
  }

  .marker-gradient::before,
  .marker-gradient::after {
    border-radius: 50%;
  }

  .tooltip-gradient::before,
  .tooltip-gradient::after {
    border-radius: 4px;
  }

  .marker.light-marker,
  .spectrum-tooltip.light-marker,
  .attribute-popup.light-marker,
  .spectrum-tooltip.tooltip-gradient,
  .circle-label-gradient {
    color: var(--p500);
  }

  /* .circle-label-gradient {
    border: 2px solid transparent; 
    background-clip: padding-box, border-box;
    background-origin: border-box;
    background-image: linear-gradient(var(--g600), var(--g600)), linear-gradient(to right, var(--first-color), var(--second-color));
  } */

  .circle-label-gradient {
    position: relative;
    border: none;
    background: none;
  }

  .circle-label-gradient::before {
    content: "";
    position: absolute;
    inset: 3px;
    background: var(--g150);
    border-radius: 50%;
    z-index: 1;
    pointer-events: none;
  }

  .circle-label-gradient::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(to right, var(--first-color) 50%, var(--second-color) 50%);
    border-radius: 50%;
    z-index: 0;
    pointer-events: none;
  }

  .circle-label-gradient>* {
    position: relative;
    z-index: 3;
  }

  .marker-gradient::before,
  .tooltip-gradient::before {
    content: "";
    position: absolute;
    inset: 3px;
    background: var(--g150);
    /* Inner Fill for Gradient */
    z-index: -2;
    pointer-events: none;
  }

  .marker-gradient::after,
  .tooltip-gradient::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(to right, var(--first-color) 50%, var(--second-color) 50%);
    z-index: -3;
    pointer-events: none;
  }

  .marker-gradient>* {
    position: relative;
    z-index: 2;
  }

  /* Attribute Details Popup */
  .attribute-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .attribute-popup {
    position: absolute;
    background: var(--white);
    border-radius: 8px;
    padding: 24px;
    box-shadow: var(--shadow-lg);
    max-width: 700px;
    width: 95%;
    max-height: 90vh;
    overflow-y: auto;
  }

  .attribute-popup .close-button {
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: none;
    color: var(--g400);
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s;
  }

  .attribute-popup .close-button:hover {
    color: var(--g600);
    background-color: var(--g200);
  }

  .attribute-popup .attribute-popup-name {
    margin: 0 0 16px 0;
    padding-right: 32px;
    font-size: 1rem;
    font-weight: var(--bold);
    color: var(--black);
  }

  .attribute-popup .description {
    margin: 0 0 24px 0;
    color: var(--p500);
    font-weight: var(--semi-bold);
  }

  .attribute-popup .poles-description {
    display: flex;
    flex-direction: row;
    gap: 32px;
    padding: 0 8px;
  }

  .attribute-popup .left-pole,
  .attribute-popup .right-pole {
    flex: 1;
    font-size: .9rem;
    font-weight: var(--normal);
  }

  .spectrum-tooltip {
    color: var(--white);
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    pointer-events: none;
    font-family: var(--headings);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 4px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;
  }

  .spectrum-tooltip.visible {
    opacity: 1;
    visibility: visible;
  }

  .traits-comparison-section {
    margin-bottom: 1rem;
    max-width: 600px;
  }

  .traits-comparison-search {
    width: 100%;
  }

  .comparison-user-indicator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    background-color: var(--white);
    border: 1px solid var(--p500);
    border-radius: 4px;
    font-size: 14px;
  }

  .clear-comparison-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    padding: 4px;
    margin-left: 8px;
    cursor: pointer;
    color: var(--g500);
    border-radius: 4px;
  }

  .clear-comparison-button:hover {
    background-color: var(--g100);
    color: var(--g700);
  }

  .comparison-user-indicator span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .marker.comparison-marker {
    background-color: var(--g500) !important;
    opacity: 0.7;
    pointer-events: none;
    z-index: 1;
    position: absolute;
  }

  .marker.comparison-marker .marker-content {
    color: var(--white);
  }

  .comparison-caret {
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--g700);
    opacity: 1 !important;
    z-index: 3;
  }

  /* Ensure the main marker is above the comparison marker */
  .marker:not(.comparison-marker) {
    z-index: 2;
  }

  /* Generate 1-on-1 Guide Button */
  .traits-comparison-section {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
  }

  .comparison-container {
    flex: 1;
  }

  .comparison-actions {
    display: flex;
    align-items: center;
    gap: 12px;
    white-space: nowrap;
  }

  .copy-prompt-button {
    gap: 6px;
    background-color: var(--p500);
    min-width: 135px;
  }

  .copy-prompt-button:hover {
    background-color: var(--p700);
  }

  .open-aria-link {
    color: var(--p500);
    text-decoration: none;
    font-size: 14px;
    transition: color 0.2s;
  }

  .open-aria-link:hover {
    color: var(--p700);
    text-decoration: underline;
  }

  .comparison-actions .button {
    background-color: var(--p500);
    color: var(--white);
  }

  .comparison-actions .button:hover {
    background-color: var(--p700);
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }

  /* Bulk Tag Actions */
  .bulk-tag-actions {
    display: flex;
    gap: 12px;
    align-items: center;
    border-radius: 4px;
    padding-top: 12px;
  }

  .bulk-tag-actions select {
    min-width: 120px;
  }

  .bulk-tag-actions .bulk-tag-select {
    min-width: 200px;
  }

  .bulk-tag-actions .selected-count {
    color: var(--text-secondary);
    font-size: 0.9rem;
    margin-left: auto;
  }

  .bulk-tag-actions button {
    font-size: 11px;
    font-family: var(--body);
  }

  .bulk-tag-actions button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }

  select.tag-operation-select,
  select.bulk-tag-select {
    font-size: 12px;
    font-family: var(--body);
  }

  /* .iscp-container {
  max-width: 130px;
} */

  /* Match Chip */
  /* .name-cell {
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-weight: var(--semi-bold);
} */

  .match-container {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .match-label {
    font-size: 11px;
    font-weight: 300;
    color: var(--g500);
  }

  .match-chip {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 2px 6px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
  }

  @media (max-width: 1024px) {

    .traits-tab,
    .traits-tab-summary {
      max-width: 100%;
    }
  }

  @media (max-width: 768px) {
    .traits-tab {
      padding-top: 30px;
    }

    .attribute-spectrum {
      padding: 0 10px;
      margin-bottom: 28px;
    }

    .spectrum-container {
      position: relative;
    }

    .spectrum-container {
      flex-direction: column;
      gap: 10px;
    }

    .spectrum-line {
      height: 40px;
      width: 100%;
    }

    .labels-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-top: 5px;
    }

    .spectrum-container>.label {
      display: none;
    }

    .label {
      width: auto;
      font-size: 11px;
    }

    .left-label {
      text-align: left;
      padding-right: 0;
    }

    .right-label {
      text-align: right;
      padding-left: 0;
    }

    .marker {
      width: 28px;
      height: 28px;
      font-size: 12px;
    }

    .tick-small {
      height: 6px;
      top: -3px;
    }

    .tick-large {
      height: 12px;
      top: -6px;
    }

    .comparison-user-indicator {
      font-size: 12px;
    }

    .comparison-container .search-bar-input {
      font-size: 12px !important;
    }

    .attribute-popup .poles-description {
      flex-direction: column;
      gap: 16px;
    }

    .attribute-popup {
      padding: 20px;
    }
  }

  /* Floating Navigation Tooltips */
  .nav-tooltip {
    position: absolute;
    background-color: var(--p300);
    color: var(--white);
    padding: 8px 12px;
    border-radius: 18px;
    font-size: 13px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s, visibility 0.2s;
    pointer-events: none;
    z-index: 1000;
    bottom: calc(100% + 2px);
    left: 50%;
    transform: translateX(-50%);
  }

  .nav-button:hover .nav-tooltip {
    opacity: 1;
    visibility: visible;
  }

  /* Always check right edge for next button tooltip */
  .nav-button.next .nav-tooltip {
    left: auto;
    right: 0;
    transform: translateX(0);
  }

  /* Left button tooltip stays centered */
  .nav-button.prev .nav-tooltip {
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 768px) {
    .nav-tooltip {
      font-size: 12px;
      padding: 6px 10px;
    }
  }

  .popup-bottom-margin {
    margin-bottom: 40px;
  }

  /* Add these meta viewport rules to prevent unwanted zooming */
  @viewport {
    zoom: 1.0;
    width: device-width;
  }

  @-ms-viewport {
    width: device-width;
  }

  .marker:hover .spectrum-tooltip {
    opacity: 1;
    visibility: visible;
  }