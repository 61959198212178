@import url('./colors.css');
@import url('./fonts.css');

*,
*::before,
*::after {
    box-sizing: border-box;
}

html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    color: var(--black);
    font-family: var(--text);
    font-weight: var(--thin);
    background-color: var(--g100);
}

@media (max-width: 768px) {
    html, body {
        overflow-x: hidden;
    }
}

h1, h2, h3 {
    font-family: var(--headings);
    margin: 0;
}

h1 {
    font-weight: var(--bold);
}

h2 {
    margin-bottom: 10px;
    font-weight: var(--semi-bold);
}

h3 {
    margin-bottom: 10px;
    font-weight: var(--semi-bold);
}

h4, h5, h6 {
    font-family: var(--text);
    font-weight: var(--bold);
}

a {
    color: var(--p500);
    font-weight: var(--bold);
    text-decoration: none;
}

.material-icons {
    font-size: 16px;
    vertical-align: middle;
}

.red-text {
    color: var(--red);
}

.green-text {
    color: var(--green);
}