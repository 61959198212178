.tags-list,
.add-tag {
    max-width: 500px;
    margin: 1rem 0;
}

.tag-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--g200);
    border-radius: 4px;
}

.add-tag {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
}