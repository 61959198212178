/* Chart Container */
.target-header,
.group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 60%;
    margin-bottom: 8px;
}

.group-header {
    max-width: 100%;
}

.group-header h2{
    margin-bottom: 0px;
}
.target-header {
    margin-bottom: 12px;
}
.target-header h2 {
    display: flex;
    align-items: center;
    margin-bottom: 0px;
}

.target-profile-avatar {
    width: 36px;
    height: 36px;
    margin-right: 8px;
    border-radius: 50%;
}

.workstyles-chart-container,
.resonance-chart-container,
.target-chart-container {
    max-width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 00px;
    margin-bottom: 30px;
    position: relative;
}

.target-chart-container {
    display: block;
}

.workstyles-chart-container {
    margin-bottom: 16px;
}

.workstyles-svg,
.target-svg,
.resonance-chart-container {
    transition: max-width 0.3s ease;
    filter: drop-shadow(var(--filter-large));
}

.resonance-chart-container {
    margin-top: -00px;
}

/* ISCP Chart */
.chart-background {
    fill: var(--white);
}

.midpoint-line {
    stroke: var(--g700);
}

.bottom-rectangle {
    fill: var(--p900);
}

.border-rectangle {
    stroke: var(--g700);
}

.sigma-line {
    stroke: var(--g250);
}

.chart-label {
    font-size: 15px;
    font-family: var(--text);
    font-weight: var(--normal);
    fill: var(--black);
}

.workstyles-svg,
.resonance-svg {
    width: 100%;
    max-width: 1119px;
    height: auto;
    margin-top: 00px;
}

.iscp-circles,
.standalone-circles {
    cursor: pointer;
}

.workstyles-text,
.standalone-text {
    font-family: var(--headings);
    font-size: 24px;
    font-weight: var(--semi-bold);
    text-anchor: middle;
    fill: white;
    cursor: pointer;
}

.standalone-text {
    font-size: 22px;
}

/* Resonance Chart */
.trait-line-background {
    stroke: var(--g100);
}

.trait-line-foreground {
    stroke: var(--g300);
}

.trait-circle-er { fill: var(--p400); }
.trait-circle-ps { fill: var(--p300); }
.trait-circle-si { fill: var(--p300); }
.trait-circle-ab { fill: var(--p200); }

.tooltip-background-er { fill: var(--p400); }
.tooltip-background-ps { fill: var(--p300); }
.tooltip-background-si { fill: var(--p300); }
.tooltip-background-ab { fill: var(--p200); }

.tooltip-text {
    fill: var(--white);
    font-size: 14px;
}

/* Quadrants Chart */
.quadrant-chart-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    gap: 5%;
}

.quadrant-chart-wrapper {
    width: 100%;
    max-width: 555px;
    margin-top: 00px;
    box-sizing: border-box;
}

.quadrants-svg {
    width: 100%;
    height: auto;
}

.q-chart-axis-label,
.q-chart-text {
    font-size: 14px;
}

.q-chart-title {
    font-weight: bold;
    font-size: 15px;
}

.q-chart-axis-label {
    font-weight: var(--bold);
}

/* Quadrant Table */
.quadrant-table-wrapper {
    width: 100%;
}

.quadrant-table tr {
    cursor: pointer;
}

/* Attribute Strip */
.attribute-strip {
    filter: drop-shadow(var(--filter-large));
}

.attribute-strip-background {
    fill: var(--white);
    rx: 8px;
}

.attribute-strip-scale {
    stroke: var(--g100);
    stroke-width: 2;
}

.attribute-strip-center {
    stroke: var(--g500);
    stroke-width: 1;
}

.attribute-strip-sigma {
    stroke: var(--g200);
    stroke-width: 1;
    /* stroke-dasharray: 4,2; */
}

.attribute-strip-marker {
    stroke-width: 2;
}

.attribute-strip-marker-i {
    fill: var(--primary);
}

.attribute-strip-marker-s {
    fill: var(--orange);
}

.attribute-strip-marker-c {
    fill: var(--teal);
}

.attribute-strip-marker-p {
    fill: var(--grey);
}

.attribute-strip-marker-er {
    fill: var(--p400);
}

.attribute-strip-marker-ps {
    fill: var(--p300);
}

.attribute-strip-marker-ab {
    fill: var(--p200);
}

.attribute-strip-text {
    fill: var(--white);
    font-size: 14px;
    font-weight: var(--semi-bold);
}

.attribute-strip-label {
    fill: var(--g500);
    font-size: 12px;
}

.quadrant-chart-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.quadrant-row-hover td {
    background-color: var(--p100) !important;
}

.quadrant-chart-icon.hovered {
    --profile-bg: var(--p900);
}

.quadrant-bg {
    fill: var(--white);
    stroke: none;
}

.quadrant-circle {
    fill: none;
    stroke: var(--g700);
    stroke-width: 1;
}

.quadrant-axis {
    stroke: var(--g200);
    stroke-width: 1;
    stroke-dasharray: 1, 1;
}

.q-chart-title,
.q-chart-text,
.q-chart-axis-label {
    fill: var(--black);
}

.q-profile-icon {
    width: 30px;
    height: 30px;
}

.axis-label {
    fill: var(--g700);
    font-size: 14px;
}

.axis-label-end {
    fill: var(--g500);
    font-size: 12px;
}

table.quadrant-table {
    margin-top: 6px;
}

.quadrant-table .workstyles-mini-chart {
    height: 44px;
    vertical-align: middle;
}

.quadrant-table td {
    vertical-align: middle;
}

.dark-mode .q-profile-icon {
    --profile-fg: var(--black);
}

/* Dark Mode */
.dark-mode .quadrant-bg {
    fill: var(--black);
}

.dark-mode .quadrant-circle {
    stroke: var(--g200);
}

.dark-mode .quadrant-axis {
    stroke: var(--g500);
}