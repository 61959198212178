/* Dark Mode Button */
.dark-mode-toggle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--p700);
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    outline: none;
    padding: 0;
    margin: 0 16px;
}

.dark-mode-toggle:hover {
    background-color: var(--p700);
}

.dark-mode-toggle svg {
    width: 20px;
    height: 20px;
    color: var(--white);
    transition: color 0.3s ease;
}

/* Button Styles for when dark mode is active*/
.dark-mode .dark-mode-toggle {
    background-color: var(--g200);
}

.dark-mode .dark-mode-toggle:hover {
    background-color: var(--white);
}

.dark-mode .dark-mode-toggle svg {
    color: var(--black);
}

.dark-mode-toggle svg {
    transition: transform 0.3s ease;
}

.dark-mode-toggle:active svg {
    transform: scale(0.9);
}

/* Dark Mode Global CSS */
body.dark-mode {
    background-color: var(--g800);
    color: var(--g250);
}

body.dark-mode {
    --profile-fg: var(--black);
    --logo: var(--black);
}

.dark-mode a {
    color: var(--p300);
}

.dark-mode table tbody td {
    background-color: var(--black);
    color: var(--g200);
}

.dark-mode table tbody tr {
    background-color: var(--black);
}

.dark-mode input,
.dark-mode textarea,
.dark-mode select {
    background-color: var(--black);
    color: var(--p100);
}

.dark-mode .search-bar-dropdown {
    background-color: var(--black);
}

.dark-mode .search-bar-option {
    color: var(--p200);
}

/* Buttons */
.dark-mode .button {
    color: var(--black);
}

.dark-mode .icon-white {
    fill: var(--black);
}

.dark-mode .button-cancel,
.dark-mode .copy-link-button, 
.dark-mode .share-model-button,
.dark-mode .button-grey {
    background-color: var(--g700);
    color: var(--g200);
}

.dark-mode .filter-button {
    background-color: var(--black);
    color: var(--g200);
}

.dark-mode .actions-dropdown, .dark-mode .archive-dropdown {
    background-color: var(--black);
    border: 1px solid var(--g500);
}

.dark-mode .actions-dropdown .action {
    color: var(--g200);
}

.dark-mode .filter-chip {
    background-color: var(--g700);
}

.dark-mode .layout-job-title {
    color: var(--g200);
}

/* Navigation Links */
.dark-mode .navigation-links li a,
.dark-mode .sidebar-navigation li a,
.dark-mode .sidebar-navigation li svg,
.dark-mode .sidebar-navigation li a.active,
.dark-mode .sidebar-navigation li a.active svg,
.dark-mode .sidebar-navigation li a:hover,
.dark-mode .sidebar-navigation li a:hover svg,
.dark-mode .magnifying-glass-icon svg,
.dark-mode .mobile-bottom-icons a,
.dark-mode .mobile-bottom-icons button {
    color: var(--black);
}

.dark-mode .navigation-links li a,
.dark-mode .sidebar-navigation li a,
.dark-mode .sidebar-navigation li svg,
.dark-mode .mobile-bottom-icons a,
.dark-mode .magnifying-glass-icon svg,
.dark-mode .mobile-bottom-icons a,
.dark-mode .mobile-bottom-icons button,
.dark-mode .mobile-bottom-icons a.inactive,
.dark-mode .mobile-bottom-icons button.inactive {
    opacity: 0.8;
}

/* Statistics Boxes */
.dark-mode .stat-box {
    background-color: var(--black);
    color: var(--g200);
}

/* ISCP Chart */
.dark-mode .chart-background {
    fill: var(--black);
}

.dark-mode .midpoint-line {
    stroke: var(--g200);
}

.dark-mode .border-rectangle {
    stroke: var(--g300);
}

.dark-mode .sigma-line {
    stroke: var(--g700);
}

/* Target Overlay */
.dark-mode .target-overlay {
    background-color: rgba(0, 0, 0, 0.8);
}

/* Target Survey */
.dark-mode .likert-card:hover {
    background-color: var(--g700);
    /* color: var(--black); */
}

.dark-mode .progress-bar {
    background-color: var(--g300);
}

/* Resonance Chart */
.dark-mode .trait-line-background {
    stroke: var(--g700);
}

.dark-mode .trait-line-foreground {
    stroke: var(--g400);
}

.dark-mode .chart-label {
    fill: var(--g100);
}

/* Interview Guide */
.dark-mode .guide-item {
    background-color: var(--black);
    color: var(--g100);
}

.dark-mode .interview-guide h2 {
    color: var(--white);
}

.dark-mode .guide-item h4 {
    color: var(--g100);
}

/* Other */
.dark-mode table tbody tr.hovered-row > td {
    background-color: var(--g700);
}

.dark-mode .toggle-button {
    background-color: var(--g700);
    color: var(--g200);
}

.dark-mode table tbody tr.selected-row td {
    background-color: var(--p900);
}

.dark-mode .toggle-button.active {
    background-color: var(--p500);
    color: var(--black);
}

.dark-mode .thumbs-icon path {
    fill: var(--g500);
}

.dark-mode .profile-section {
    background-color: var(--black);
}

.dark-mode .form-group label {
    color: var(--g200);
}

.dark-mode input.form-input,
.dark-mode textarea.form-input,
.dark-mode select.form-input {
    border-color: var(--g500);
}

.dark-mode .search-input, .dark-mode .filter-checkbox-list {
    border: 1px solid var(--g500);
}

.dark-mode .search-bar-option.highlighted {
    background-color: var(--p500); 
    color: var(--white);
}

/* Modals & Dropdowns */
.dark-mode .header-profile-dropdown .dark-mode-dropdown .theme-options {
    background-color: var(--black);
}

.dark-mode .header-profile-dropdown .dark-mode-dropdown .theme-options button:hover {
    background-color: var(--g800);
}

.dark-mode .header-profile-dropdown .dark-mode-dropdown .theme-options button.active {
    background-color: var(--p900);
    color: var(--p200);
}

.dark-mode .sidebar-navigation .theme-menu .theme-submenu button {
    color: var(--black);
}

.dark-mode .modal-overlay {
    background-color: rgba(0, 0, 0, 0.6);
}

.dark-mode .filter-close-button svg {
    fill: var(--black);
}

.dark-mode .filter-modal,
.dark-mode .header-profile-dropdown,
.dark-mode .more-tabs-dropdown {
    background-color: var(--black);
}

.dark-mode .new-item-modal-content {
    background-color: var(--g700);
}

.dark-mode .modal-header,
.dark-mode .header-profile-dropdown li:hover {
    background-color: var(--g700);
}

.dark-mode .modal-header {
    border-bottom: 1px solid var(--g500);
}

.dark-mode .modal-content {
    background-color: var(--black);
    color: var(--g100);
}

.dark-mode .modal-lh-chart {
    fill: var(--g800);
}

.dark-mode .modal-lh-line {
    stroke: var(--g500);
}

.dark-mode .date-selector-dropdown {
    background-color: var(--black);
    border: 1px solid var(--g500);
    border-radius: 0;
}

.dark-mode .date-selector-dropdown li:hover {
    background-color: var(--g800);
}

.dark-mode .suggestions-menu {
    background-color: var(--black);
    border: 1px solid var(--g500);
}

.dark-mode .suggestion-item {
    color: var(--g100);
}

.dark-mode .attribute-popup {
    background-color: var(--g800);
}

/* Date Picker */
.dark-mode .react-datepicker {
    background-color: var(--black);
    border: 1px solid var(--g500) !important;
}

.dark-mode .react-datepicker__header {
    background-color: var(--g700) !important;
    border-bottom: 1px solid var(--g500) !important;
}

.dark-mode .react-datepicker__current-month,
.dark-mode .react-datepicker-time__header,
.dark-mode .react-datepicker-year-header,
.dark-mode .react-datepicker__day-name,
.dark-mode .react-datepicker__day,
.dark-mode .react-datepicker__time-name {
    color: var(--g200) !important;
}

.dark-mode .react-datepicker__day:hover {
    background-color: var(--g700) !important;
}

.dark-mode .react-datepicker__day--selected,
.dark-mode .react-datepicker__day--keyboard-selected,
.dark-mode .react-datepicker__day--in-selecting-range,
.dark-mode .react-datepicker__day--in-range {
    background-color: var(--p500) !important;
    color: var(--black) !important;
}

.dark-mode .react-datepicker__day--in-range:not(.react-datepicker__day--selected) {
    background-color: var(--p700) !important;
    color: var(--g200) !important;
}

.dark-mode .react-datepicker__day--outside-month {
    color: var(--g500) !important;
}

/* Pricing Table */
.dark-mode .pricing-tier-active {
    background-color: var(--g700);
    color: var(--g300);
}

.dark-mode .pricing-table-contract, 
.dark-mode .pricing-table-info-text,
.dark-mode .pricing-table-slider-label {
    color: var(--g200);
}

.dark-mode .pricing-tier-popular {
    background-color: var(--p500);
    color: var(--black);
}

.dark-mode .pricing-table-toggle span {
    color: var(--g200);
}

.dark-mode .pricing-table-toggle span.active {
    color: var(--p500);
}

.dark-mode .rc-slider-rail {
    background-color: var(--g500) !important;
    height: 10px !important; 
  }

/* Floating Navigation */
.dark-mode .floating-navigation svg {
    fill: var(--black);
}

/* Email Admins */
.dark-mode .email-input-container {
    background-color: var(--black);
}

.dark-mode .email-chip {
    background-color: var(--p900);
}

/* Email Modal */
.dark-mode .email-modal-content {
    background-color: var(--black);
}

/* Traits Tab */
.dark-mode .traits-tab {
    background-color: var(--black);
}

.dark-mode .line-background,
.dark-mode .tick {
    background-color: var(--g200);
}
  
.dark-mode .label {
    color: var(--g200);
  }

.dark-mode .attribute-details-popup {
background-color: var(--g800);
color: var(--g150);
}

.dark-mode .attribute-popup-name,
.dark-mode .attribute-details-popup strong {
    color: var(--g100);
}

/* Export Options */
.dark-mode .export-button .dropdown-button-icon,
.dark-mode .export-button .dropdown-icon {
    fill: var(--black);
}

.dark-mode .dropdown-button-menu {
    background-color: var(--black);
    border: 1px solid var(--g900);
}

.dark-mode .dropdown-button-menu .dropdown-button-item {
    color: var(--g200);
}

.dark-mode .dropdown-button-menu .dropdown-button-item:hover {
    background-color: var(--p900);
}

/* Collapsible Sections */
.dark-mode .section-header {
    background-color: var(--black);
}

.dark-mode .section-header:hover {
    background-color: var(--p900);
}

.dark-mode .section-header h2 {
    color: var(--g200);
}

.dark-mode .caret-icon {
    color: var(--g200);
}

.dark-mode .section-content {
    border-left-color: var(--p700);
}

/* Spectrum Comparison User */
.dark-mode .comparison-user-indicator {
    background-color: var(--black);
}

/* Tags */
.dark-mode .tag-chip,
.dark-mode button.tag-menu-button {
    color: var(--black);
}

/* Aria Chat */
.dark-mode .aria-chat-popup {
    background-color: var(--black);
    border: 1px solid var(--g700);
}

.dark-mode .aria-chat-popup .person-chip {
    background-color: var(--g700);
}

.dark-mode .aria-chat-popup .prompt-button {
    background-color: var(--g800);
    color: var(--g200);
}

.dark-mode .aria-chat-button svg {
    color: var(--black);
}

.dark-mode .profile-tag {
    background: var(--p700);
    color: var(--white);
}

/* Share Modal */
.dark-mode .share-modal-dropdown {
    background-color: var(--black);
    border: 1px solid var(--g500);
}

.dark-mode .share-modal-content {
    background-color: var(--black);
}

.dark-mode .share-modal-dropdown-item:hover {
    background-color: var(--g800);
}

.dark-mode .shared-users-container {
    background-color: var(--black);
    border: 1px solid var(--g500);
}

.dark-mode .shared-user-item {
    color: var(--g200);
    border-bottom: 1px solid var(--g700);
}

.dark-mode .shared-user-item:hover {
    background-color: var(--g800);
}

.dark-mode .shared-user-name {
    color: var(--g100);
}

.dark-mode .shared-user-email {
    color: var(--g300);
}

.dark-mode .advisor-chip {
    color: var(--black);
}

/* Quadrant Chart Table Hover Effects */
.dark-mode .quadrant-row-hover td {
    background-color: var(--g700) !important;
    color: var(--g100);
}

/* Quadrant Chart Tooltip */
.dark-mode .tooltip.q-chart-text {
    background-color: var(--g800) !important;
    border: 1px solid var(--g600) !important;
    color: var(--g100);
}

.dark-mode .tooltip.q-chart-text strong {
    color: var(--g50);
}

/* Assessment Stats */
.dark-mode .assessment-stats {
    background-color: var(--black);
    border: 1px solid var(--g700);
}

.dark-mode .assessment-stats .stats-row {
    color: var(--g200);
}

.dark-mode .assessment-stats .stats-row strong {
    color: var(--g100);
}

.dark-mode .assessment-stats .stats-row span {
    color: var(--g200);
}
