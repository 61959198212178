:root {
    /* Define the primary colors */
    --primary: #815DD3;
    --secondary: #815DD3;
    --purple: #815DD3;
    --orange: #ED9A45;
    --teal: #56C6AA;
    --grey: #939195;
    --black: #1D1A20;
    --white: #ffffff;

    /* Use Primary for p500 */
    --p500: var(--primary);

    /* Primary Variations */
    --p900: color-mix(in srgb, var(--primary) 40%, black);
    --p700: color-mix(in srgb, var(--primary) 80%, black);
    --p400: color-mix(in srgb, var(--primary) 90%, white);
    --p300: color-mix(in srgb, var(--primary) 80%, white);
    --p200: color-mix(in srgb, var(--primary) 60%, white);
    --p100: color-mix(in srgb, var(--primary) 10%, white);

    --o500: var(--orange);
    --t500: var(--teal);

    --g100: #FAF8FC;
    /*--g100: color-mix(in srgb, var(--primary) 1%, white);*/
    --g150: #F6F1F6;
    --g200: #cecece;
    --g250: #ccc;
    --g300: color-mix(in srgb, var(--g500) 70%, white);
    --g400: color-mix(in srgb, var(--g500) 90%, white);
    --g500: var(--grey);
    --g600: color-mix(in srgb, var(--grey) 80%, black);
    --g700: color-mix(in srgb, var(--g500) 40%, black);
    --g800: color-mix(in srgb, var(--g500) 30%, black);

    --green: #45a36d;
    --red: #cd5151;

    /* RGB values for match chips */
    --green-rgb: 34, 197, 94;     /* #22C55E */
    --red-rgb: 239, 68, 68;       /* #EF4444 */
    --o500-rgb: 249, 115, 22;     /* #F97316 */

    --profile-bg: var(--primary);
    --profile-fg: var(--white);
    --archetype-color: var(--p300);

    --shadow-large: 0 2px 14px 0 rgba(0, 0, 0, .15);
    --shadow-input: 0px 1px 6px rgba(0, 0, 0, .15);
    --shadow-small: 0px 1px 4px rgba(0, 0, 0, .05);

    --filter-large: 0 2px 14px rgba(0, 0, 0, .1);
    --filter-intense: 0 2px 14px rgba(0, 0, 0, .1);
    --purple-highlight-sm: 0 0 0 4px rgba(106, 27, 154, 0.3);

    --filter-assessment-table: 0 2px 8px rgba(0, 0, 0, .1);

    --purple-highlight: 0px 0px 6px rgba(210, 195, 228, 1);
}
