.search-bar {
    position: relative;
    width: 100%;
}

.search-bar-input-wrapper {
    position: relative;
}

.search-bar-input {
    width: 100%;
    padding: 10px 40px 10px 16px;
    border: none;
    border-radius: 14px;
    font-size: 14px;
    font-family: var(--text);
    min-width: 320px;
    box-shadow: var(--shadow-small);
    outline: none;
}

@media screen and (-webkit-min-device-pixel-ratio:0) and (max-device-width:1024px) {
    .search-bar-input:focus {
        font-size: 16px;
    }    
}

@supports (-webkit-overflow-scrolling: touch) {
    .search-bar-input {
      font-size: 16px;
    }
 }

.search-bar-input::placeholder {
    color:var(--g300);
    font-weight: var(--thin);
}

.search-bar.focused .search-bar-input {
    border-radius: 16px 16px 0 0;
}

.search-bar-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.search-bar-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: var(--white);
    border-top: none;
    border-radius: 0 0 16px 16px;
    max-height: 240px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: var(--shadow-small);
}

.search-bar-option {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
    color: var(--primary);
    font-size: 0.9rem;
}

.search-bar-option .result-icon {
    flex-shrink: 0; 
    margin-right: 8px;
    color: var(--primary);
}

.search-bar-option .result-text {
    flex-grow: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
}

.search-bar-option.highlighted {
    background-color: var(--p500); 
    color: var(--white);
}

.search-bar-option.highlighted .result-icon {
    color: var(--white); 
}

.search-bar-no-options {
    padding: 16px;
    color: var(--g400);
    font-size: 0.8rem;
    font-style: italic;
}