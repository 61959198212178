.app-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    overflow: auto;
}

.main-content {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
    padding: 0 40px 0 116px;
}

.content-area {
    /*width: calc(100% - 152px);*/
    padding: 54px 0 0 0;
    flex-grow: 1;
}

.page-title-container {
    position: relative;
    z-index: 2;
    padding: 20px 1.5rem;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
}

.info-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    color: inherit;
}

.content-after-title-wrapper {
    display: flex;
    align-items: center;
}

.layout-profile-icon {
    width: 66px;
    height: 66px;
    vertical-align: middle;
}

.title-wrapper {
    display: flex;
    align-items: center;
    /* gap: 4px; */
}

.profile-icon-container {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 12px;
}

.profile-name-chip {
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--p300);
    color: white;
    font-size: 12px;
    font-weight: var(--semi-bold);
    padding: 2px 8px;
    border-radius: 12px;
    text-transform: capitalize;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s ease;
    pointer-events: none;
}

.profile-icon-container:hover .profile-name-chip {
    opacity: 1;
}

.title-content {
    flex-grow: 1;
}

.title-content-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.title-content-icon svg {
    color: var(--p500);
}

/* .title-content h1 {
    margin-top: -5px;
} */

.job-title {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 5px;
}

.job-title-edit-icon {
    color: var(--primary);
    cursor: pointer;
    position: relative;
    top: 3px;
}

.layout-job-title {
    color: var(--g700);
    font-size: 15px;
    margin-top: -5px;
    margin-bottom: 5px;
    font-weight: var(--normal);
}

.job-title-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.job-title-input > input {
    flex: 1;
    min-width: 250px; 
}

.job-title-input > div {
    display: flex;
    gap: 4px;
}

.job-title-input button {
    margin-left: 5px;
}

.job-title-input .button .job-title-button-text {
    display: inline;
}

.job-title-input .button .job-title-button-icon {
    display: none;
}

.content-wrapper {
    padding: 0 1.5rem;
    margin-top: 20px;
}

.content-after-title-wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.content-after-title {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
}

.title-date-calculated {
    font-weight: var(--semi-bold);
}

.date-selector-dropdown {
    position: absolute;
    background-color: var(--white);
    border: 1px solid var(--g100);
    border-radius: 7px;
    padding: 0;
    margin-top: 22px;
    list-style: none;
    z-index: 100;
}

.date-selector-dropdown li {
    padding: 8px 12px;
    cursor: pointer;
}

.date-selector-dropdown li:hover {
    background-color: var(--g100);
}

.date-selector-dropdown-icon {
    width: 10px;
    margin-left: 5px;
    vertical-align: middle;
    color: var(--black);
}

.title-metadata {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/* Tags */
.tag-selection-container {
    padding: 20px;
}

.tag-select {
    width: 100%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid var(--g200);
    border-radius: 4px;
    font-size: 14px;
}


.tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    /* margin-top: -10px; */
    margin-left: 130px;
}

.tag-chip {
    background: var(--p300);
    color: var(--white);
    padding: 4px 8px 4px 8px;
    border-radius: 16px;
    font-size: 11px;
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    font-weight: var(--normal);
}

.tag-icon {
    opacity: 0.7;
}

.tag-menu-button {
    background: none;
    border: none;
    padding: 0 0 0 2px;
    cursor: pointer;
    font-size: 11px;
    color: var(--white);
    opacity: 0.4;
    transition: opacity 0.2s;
    display: flex;
    align-items: center;
}

.tag-menu {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 4px;
    background: white;
    border: 1px solid var(--g200);
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.tag-menu-item {
    padding: 8px 16px;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    color: var(--g700);
    font-size: 14px;
}

.tag-menu-item:hover {
    background: var(--g100);
}

.add-tag-button {
    background: none;
    border: none;
    color: var(--p500);
    font-size: 12px;
    cursor: pointer;
    padding: 4px 8px;
}

.add-tag-button:hover {
    background: var(--g100);
    border-radius: 4px;
}

.tag-dropdown-container {
    position: relative;
}

.tag-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 4px;
    background: transparent;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    z-index: 1000;
}

.tag-dropdown-item {
    padding: 4px 12px 4px 8px;
    border: none;
    background: var(--p200);
    width: 100%;
    text-align: left;
    cursor: pointer;
    color: var(--white);
    font-size: 11px;
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 16px;
    margin-bottom: 4px;
}

.tag-dropdown-item:hover {
    background: var(--p500);
}

.tag-chip:hover .tag-menu-button {
    opacity: 1;
}

.export-button-wrapper {
    position: absolute;
    top: 150px;
    right: 0;
    z-index: 2;
}

@media (max-width: 860px){
    .title-content h1{
        font-size: 22px;
        line-height: 23px;
    }
}