.statistics-boxes {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.stat-box {
    background-color: var(--white);
    border-radius: 8px;
    padding: 1rem;
    display: flex;
    align-items: center;
    width: 30%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
}

.stat-box:hover {
    transform: translateY(-5px);
}

.stat-box svg {
    color: var(--primary);
    margin-right: 1rem;
}

.dashboard-stat-icon {
    font-size: 36px;
}

.stat-info {
    display: flex;
    flex-direction: column;
}

.stat-info h3 {
    font-size: 1.7rem;
    margin: 0;
    color: var(--g900);
}

.stat-info p {
    font-size: 1rem;
    margin: 0;
    color: var(--g600);
}

/* Assessment Stats */
.assessment-stats {
    padding: 1rem;
    margin-bottom: 1rem;
    background-color: var(--white);
    border-radius: 4px;
    font-size: 0.9rem;

    .stats-row {
        margin-bottom: 0.5rem;

        &:last-child {
            margin-bottom: 0;
        }

        strong {
            margin-right: 0.5rem;
            min-width: 100px;
            display: inline-block;
        }

        span {
            margin-right: 1rem;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 768px) {
    .stat-box {
        width: 32%;
    }

    .stat-box p {
        display: none;
    }

    .dashboard-stat-icon {
        font-size: 20px;
    }

    .stat-info h3 {
        font-size: 1.1rem;
    }
}