.profile-heading-bar {
    display: flex;
    gap: 16px;
}

.profile-image {
    width: 120px;
    height: auto;
    align-self: center;
}

.profile-text-content {
    flex: 1;
    align-self: center;
}

.profile-export-icon {
    margin-left: auto;
    margin-top: 6px;
    align-self: flex-start;
}

.chart-export-icons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -20px;
    gap: 8px;
}

.chart-export-icons span {
    cursor: pointer;
}

.profile-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 2rem;
}

.placard-export-icon {
    align-self: flex-end;
    margin-bottom: -20px;
}

.profile-section {
    background-color: var(--white);
    padding: 20px;
    border-radius: 7px;
}

.profile-shine {
    font-weight: var(--semi-bold);
}

.profile-container li {
    font-weight: var(--normal);
    margin-bottom: 6px;
}

.profile-container h2 {
    color: var(--p500);
}

.profile-container h3 {
    font-size: 1.3rem;
    color: var(--p300);
}

.profile-psp-category {
    font-weight: bold;
}

.profile-psp-job-title {
    font-style: italic;
}

.profile-psp-description {
    margin-left: 1em;
    font-size: 14px;
}

/* Collapsible Sections */
.section-container {
    margin: 0 0 1.5rem 0;
}

.section-header {
    display: flex;
    align-items: center;
    padding: 5px 0;
    background-color: var(--white);
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s ease;
    gap: 6px;
}

.section-header:hover {
    background-color: var(--p100);
}

.section-header h2 {
    margin: 0;
    font-size: 0.9rem;
    color: var(--g900);
    flex: 1;
}

.caret-icon {
    color: var(--g700);
    transition: transform 0.2s ease;
    min-width: 24px;
}

.caret-icon.rotated {
    transform: rotate(-90deg);
}

.section-content {
    padding: 1.5rem 2rem;
    margin-left: 20px;
    border-left: 1px solid var(--p200);
}

.workstyles-chart-container,
.resonance-chart-container {
    max-width: calc(60% - 2rem);
}

.profile-container:last-child {
    margin-bottom: 0;
}

.profile-tab-header {
    display: flex;
    justify-content: flex-end;
    position: relative;
    height: 0;
}

.section-container {
    position: relative;
    z-index: 1;
}

.profile-details-container {
    display: flex;
    gap: 2rem;
    margin-top: 1.5rem;
}

.profile-sidebar {
    flex: 0 0 300px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.profile-main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.profile-section {
    background-color: var(--white);
    padding: 20px;
    border-radius: 7px;
}

.profile-section h3 {
    font-size: 1.3rem;
    color: var(--p300);
    margin-bottom: 1rem;
}

.profile-list {
    list-style-type: disc;
    padding-left: 1.5rem;
}

.profile-list li {
    font-weight: var(--normal);
    margin-bottom: 0.5rem;
}

.profile-text-content {
    line-height: 1.5;
}