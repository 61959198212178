table {
    width: 100%;
    padding: 0;
    border-collapse: separate;
    border-spacing: 0;
    background-color: transparent;
}

table thead th {
    background-color: transparent;
    color: var(--g500);
    font-size: 12px;
    font-weight: var(--normal);
    text-align: left;
    border: none;
    height: auto;
}

table thead tr {
    height: 40px;
    vertical-align: top;
}

table td {
    color: var(--black);
    font-size: 13px;
    font-weight: var(--normal);
    background-color: var(--white);
    overflow: hidden;
    padding: 8px 6px;
}

table tbody {
    filter: drop-shadow(var(--filter-large));
}

thead .checkbox-column input[type="checkbox"] {
    margin: 0 4px;
}

.infinite-scroll-component  {
    overflow: visible !important;
}

.assessment-table tbody {
    filter: drop-shadow(var(--filter-assessment-table));
}

.copy-user-scores .button {
    margin-left: 10px;
}

table tbody tr:first-child td:not([style*="display: none"]):first-of-type {
    border-top-left-radius: 7px;
}

table tbody tr:first-child td:not([style*="display: none"]):last-of-type {
    border-top-right-radius: 7px;
}

table tbody tr:last-child td:not([style*="display: none"]):first-of-type {
    border-bottom-left-radius: 7px;
}

table tbody tr:last-child td:not([style*="display: none"]):last-of-type {
    border-bottom-right-radius: 7px;
}

table tbody tr {
    background-color: var(--white);
}

table thead .multiples-column svg {
    width: 18px;
    height: 18px;
    fill: var(--g500);
}

.multiples-column-icon svg {
    width: 20px;
    height: 20px;
    padding-right: 4px;
}

table tr {
    height: 56px;
}

table th:first-child, table td:first-child {
    padding-left: 16px;
}

table th:last-child, table td:last-child {
    position: relative;
    padding-right: 10px;
}

.sortable-column {
    cursor: pointer;
}

.iscp-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.workstyles-mini-chart {
    cursor: pointer;
}

.svg-mini {
    height: 45px;
}

tr.recents-button-row {
    height: 60px;
}

.infinite-loading {
    padding-top: 20px;
    color: var(--g500);
}

.recents-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.recents-button-container .left-button {
    display: flex;
    justify-content: flex-start;
}

.recents-button-container .right-button {
    display: flex;
    justify-content: flex-end;
}

.recents-button-container .button {
    white-space: nowrap;
}

.icon-header {
    display: flex;
    align-items: center;
    justify-content: end;
    height: 100%;
}

.action-row-icon {
    text-align: end;
}

table tbody tr.selected-row td {
    background-color: var(--g100);
    padding-bottom: 10px;
}

@media (max-width: 767px) {
    .recents-button-container .button {
        font-size: 0.9em;
        padding: 8px 12px;
    }
}

@media screen and (max-width: 768px) {
    table td {
        font-size: 14px;
    }
}

@media screen and (max-width: 1024px) {
    /* .email-column, .multiples-column, .position-column, .archetype-column, .recent-assessments-email {
        display: none;
    } */

    /* table td.name-column, table th.name-column {
        padding-left: 12px;
    }

    table td.date-completed-column {
        padding-right: 10px;
    } */
}

@media screen and (max-width: 768px) {
    table tbody tr:first-child td:not([style*="display: none"]):first-of-type,
    table tbody tr:first-child td:not([style*="display: none"]):last-of-type,
    table tbody tr:last-child td:not([style*="display: none"]):first-of-type,
    table tbody tr:last-child td:not([style*="display: none"]):last-of-type {
        border-radius: 0;
    }

    /* .infinite-scroll-component {
        padding-inline: 8px;
    } */
}

th svg {
    vertical-align: middle;
    margin-left: 4px;
    height: 12px;
    width: 12px;
}

.assessment-table-container {
    width: 100%;
    table-layout: auto;
}

.assessment-table-container td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mobile-only-icon {
    margin: 0 auto;
}

@media (max-width: 768px) {
    .mobile-only-icon {
        display: block;
    }
}

.share-hover-icon {
    opacity: 0;
    cursor: pointer;
    margin-left: 4px;
    transition: opacity 0.2s ease-in-out;
    color: var(--p500);
}

table tbody tr:hover .share-hover-icon {
    opacity: 1;
}

.shared-with-cell {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.advisor-chips {
    display: flex;
    align-items: center;
    gap: 2px;
}